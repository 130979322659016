/* eslint no-case-declarations:"off" */
import { normalize } from './services';

// Action types
export const UPSERT_EVENTS = 'UPSERT_EVENTS';
export const REMOVE_EVENTS = 'REMOVE_EVENTS';

// Action creators
export const upsertEvents = (events) => ({
  type: UPSERT_EVENTS,
  events,
});

export const removeEvents = (eventIds) => ({
  type: REMOVE_EVENTS,
  eventIds,
});

// Reducer
const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPSERT_EVENTS:
      return {
        ...state,
        ...action.events.reduce((acc, event) => {
          const currentEvent = state[event.id] || {};
          const currentRefCount = currentEvent.refCount || 0;
          const toAddEvent = {
            ...currentEvent,
            ...normalize(event),
            refCount: currentRefCount + 1,
          };

          return {
            ...acc,
            [event.id]: toAddEvent,
          };
        }, {}),
      };

    case REMOVE_EVENTS:
      const eventIds = action.eventIds.map((id) => parseInt(id, 10));

      return Object.keys(state).map((el) => parseInt(el, 10)).reduce((acc, eventId) => {
        const event = state[eventId];

        // If the event is not one of the ones we want to remove
        // we will add it to the acc without any change
        if (!eventIds.includes(eventId)) {
          return {
            ...acc,
            [eventId]: event,
          };
        }

        // If the event wants to be removed and it is the last ref count
        // we wont add it to the acc
        if (event.refCount === 1) {
          return acc;
        }

        // In any other case add the event to the acc decreading refCount by 1
        return {
          ...acc,
          [eventId]: {
            ...event,
            refCount: event.refCount - 1,
          },
        };
      }, {});

    default:
      return state;
  }
};
