import PropTypes from 'prop-types';
import { useBetBuilder } from 'sportsbook-hooks';
import { t } from 'bv-i18n';

export const PIULink = ({
  outcomeId, eventId, marketTypeId, betBuilder2,
}) => {
  const { supported, navigate } = useBetBuilder({ marketTypeId, eventId, outcomeId });

  return supported && !betBuilder2
    ? (
      <div className="priceitup-event__edit bvs-link bvs-label is-info" onClick={navigate}>
        {t('edit')}
      </div>
    ) : null;
};

PIULink.propTypes = {
  outcomeId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  marketTypeId: PropTypes.number.isRequired,
  betBuilder2: PropTypes.bool.isRequired,
};

export default PIULink;
