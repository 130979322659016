import { compose, negate } from 'underscore';
import { makeGetMarket } from './selector';
import {
  registerMarket as register,
  unregisterMarket as unregister,
} from './event_bus';

const lastInState = (market) => market.refCount === 1;
const geMarket = makeGetMarket();

export default (store) => (next) => (action) => {
  const state = store.getState();
  const toMarket = (marketId) => geMarket(state, { marketId });
  const notInState = compose(negate, toMarket);

  switch (action.type) {
    case 'REMOVE_MARKETS': {
      const { marketIds } = action;

      marketIds
        .map(toMarket)
        .filter(Boolean)
        .filter(lastInState)
        .forEach(unregister);

      break;
    }

    default:
      break;
  }

  next(action);

  switch (action.type) {
    case 'ADD_MARKETS':
    case 'UPSERT_MARKETS': {
      const { markets } = action;

      markets
        .filter(notInState)
        .forEach(register);

      break;
    }

    default:
      break;
  }
};
