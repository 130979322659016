import PropTypes from 'prop-types';
import { format } from 'bv-helpers/price';
import classnames from 'classnames';

const bandLabel = (priceBand) => {
  if (priceBand[0] === '<' || priceBand[0] === '>' || priceBand[1] === '>') {
    return [
      /\d/.test(priceBand[0]) ? priceBand[1] : priceBand[0],
      format(priceBand[/\d/.test(priceBand[0]) ? 0 : 1]),
    ].join(' ');
  }

  return [
    format(priceBand[0]),
    format(priceBand[1]),
  ].join(' - ');
};

const PriceBands = ({ priceBands, activeTab, setActiveTab }) => (
  priceBands.length ? (
    <div className="priceitup-bands-wrapper">
      <div className="priceitup-bands">
        { priceBands.map((priceBand, index) => (
          <div className="priceitup-bands__item bvs-link">
            <button
              type="button"
              className={classnames('bvs-button-chip is-small', {
                active: index === activeTab,
              })}
              onClick={() => setActiveTab(index)}
            >
              {bandLabel(priceBand)}
            </button>
          </div>
        ))}
      </div>
    </div>
  ) : null
);

PriceBands.propTypes = {
  priceBands: PropTypes.instanceOf(Array).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default PriceBands;
