import { betBuilderSupported } from 'sportsbook-helpers';
import { replaceFragment } from 'bv-helpers/location';
import { useFeature } from 'bv-hooks';

const betBuilderNavigate = (eventId, outcomeId) => {
  const fragment = outcomeId
    ? `bet-builder-launcher/${eventId}/outcome/${outcomeId}`
    : `bet-builder-launcher/${eventId}`;

  replaceFragment(fragment);
};

export default ({ marketTypeId, eventId, outcomeId }) => {
  const [betBuilderEnabled, featureResolved] = useFeature('bet-builder');

  const supported = featureResolved
    && betBuilderEnabled
    && betBuilderSupported(marketTypeId);

  return {
    supported,
    navigate: () => betBuilderNavigate(eventId, outcomeId),
  };
};
