import PropTypes from 'prop-types';

import formatPrice from 'Sportsbook/helpers/format_price';

const PreviousPrice = ({ previousPrice, antePreviousPrice }) => (previousPrice ? (
  <div className="upcoming-races__outcome-subprice">
    { antePreviousPrice && [
      formatPrice(antePreviousPrice),
      <span className="upcoming-races__outcome-spacer" />,
    ]}
    {formatPrice(previousPrice)}
  </div>
) : null);

PreviousPrice.propTypes = {
  previousPrice: PropTypes.string.isRequired,
  antePreviousPrice: PropTypes.string.isRequired,
};

export default PreviousPrice;
