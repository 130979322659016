import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';
import { Button } from 'bv-components';
import { withFeature } from 'bv-hocs';
import { betBuilderSupported } from 'sportsbook-helpers';

const PIUButton = ({
  featureEnabled, liveEvent, marketTypeId, eventId, betBuilder2,
}) => (featureEnabled && !liveEvent && betBuilderSupported(marketTypeId) && !betBuilder2 ? (
  <div className="priceitup-navigate-container" onClick={() => { setFragment(`/bet-builder-launcher/${eventId}?clickSource=bb-quick-launch`); }}>
    <div className="priceitup-launch">
      <div className="priceitup-launch__title">
        <span>{t('javascript.bet_builder.launch_title')}</span>
        <span className="priceitup-launch__title-icons" />
      </div>
      <div className="priceitup-launch__button">
        <Button
          to={`/bet-builder-launcher/${eventId}?clickSource=bb-quick-launch`}
          primary
        >
          {t('javascript.bet_builder.launch_button')}
        </Button>
      </div>
    </div>
  </div>
) : null);

PIUButton.propTypes = {
  eventId: PropTypes.number.isRequired,
  liveEvent: PropTypes.bool.isRequired,
  marketTypeId: PropTypes.number.isRequired,
  betBuilder2: PropTypes.bool.isRequired,
  featureEnabled: PropTypes.bool.isRequired,
};

export default withFeature('bet-builder')(PIUButton);
