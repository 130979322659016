import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { throttle } from 'underscore';

import { addToBetslip, removeFromBetslip } from '../betslip/thunks';
import { makeGetOutcome, makeGetEnhnacedOutcomeByOutcomeId } from './selector';
import { makeOutcomeInBetslip } from '../betslip/selector';
import presentOutcome from './presenter';

/*

  USAGE:

  This HOC will allow us to wrap a component and provide it with capabilities
  to represent an outcome

  specifically, this HOC will inject following props into the wrapped component:

  - selected:       boolean that will indicates if the outcome is present in the
                    betslip or not

  - toggleBetslip:  function that will perform the addition / removal of the
                    outcome from the betslip given if it is already present or not
                    on the betslip, this is the recommended function to be used
                    instead of the independent addToBetslip / removeFromBetslip

  - addToBetslip:       Will try to add the outcome to the betslip

  - removeFromBetslip:  Will try to remove the outcome to the betslip

  - outcome:        The actual outcome properties

  - enhancedOutcome: In case we have an enhanced outcome offer related outcome info
                     for the outcome, otherwise it's null

  The HOC will accept an object as a parameter, right now the only option
  available is the one to specify the name of the prop that will represent
  the outcome id, by default it is just 'id' but it could be for instance 'outcomeId'

*/

export default (WrappedComponent, { idAttribute = 'id' } = {}) => {
  const makeMapStateToProps = () => {
    const getOutcome = makeGetOutcome();
    const outcomeInBetslip = makeOutcomeInBetslip();
    const getEnhnacedOutcomeByOutcomeId = makeGetEnhnacedOutcomeByOutcomeId();

    return (state, ownProps) => ({
      outcome: getOutcome(state, ownProps),
      selected: outcomeInBetslip(state, ownProps),
      enhancedOutcome: getEnhnacedOutcomeByOutcomeId(state, ownProps),
    });
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    addToBetslip: (opts = {}) => {
      dispatch(addToBetslip(ownProps[idAttribute], {
        clickSource: opts.clickSource || ownProps.clickSource,
        enhancedOutcome: opts.enhancedOutcome,
      }));
    },

    removeFromBetslip: () => {
      dispatch(removeFromBetslip(ownProps[idAttribute]));
    },
  });

  class WithOutcome extends PureComponent {
    constructor(props) {
      super(props);

      this.toggleBetslip = this.toggleBetslip.bind(this);
    }

    toggleBetslip(opts = {}) {
      const {
        selected,
        removeFromBetslip: doRemoveFromBetslip,
        addToBetslip: doAddToBetslip,
        enhancedOutcome,
      } = this.props;

      return selected
        ? doRemoveFromBetslip()
        : doAddToBetslip({
          ...opts,
          enhancedOutcome,
        });
    }

    render() {
      const { outcome, enhancedOutcome } = this.props;

      return (
        <WrappedComponent
          {...this.props}
          outcome={presentOutcome(outcome || {})}
          enhancedOutcome={enhancedOutcome}
          toggleBetslip={throttle(this.toggleBetslip, 500, { trailing: false })}
        />
      );
    }
  }

  WithOutcome.propTypes = {
    addToBetslip: PropTypes.func.isRequired,
    removeFromBetslip: PropTypes.func.isRequired,
    toggleBetslip: PropTypes.func,
    selected: PropTypes.bool.isRequired,
    outcome: PropTypes.instanceOf(Object).isRequired,
    enhancedOutcome: PropTypes.shape({
      outcomeId: PropTypes.number.isRequired,
      active: PropTypes.bool.isRequired,
      minStake: PropTypes.number.isRequired,
      maxStake: PropTypes.number.isRequired,
      fractionalEnhancedOdds: PropTypes.string.isRequired,
    }),
  };

  WithOutcome.defaultProps = {
    enhancedOutcome: null,
    toggleBetslip: () => {},
  };

  WithOutcome.displayName = 'withOutcome';

  return connect(makeMapStateToProps, mapDispatchToProps)(WithOutcome);
};
