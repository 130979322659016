/*

  ------------ DO NOT USE!!!!!!!!! -----------------------------------

  This is legacy stuff forcing us to wrap whatever component into another HTML tag.

  Instead of this, withMarket HOC (with_market.jsx) which is more flexible

  If you work on any feature that use this component, please take the time
  to replace it with the HOC

------------------------------------------------------------------------

*/

import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const Market = (props) => {
  const {
    children, tagName, id, className,
  } = props;

  const renderChildren = () => Children.map(
    children, (child) => cloneElement(child, props),
  );

  const Tag = `${tagName}`;

  return (
    <Tag className={`market-${id} ${className}`}>
      {renderChildren()}
    </Tag>
  );
};

Market.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  tagName: PropTypes.string,
};

Market.defaultProps = {
  className: '',
  tagName: 'div',
};

export default Market;
