import PropTypes from 'prop-types';
import classnames from 'classnames';
import withOutcome from 'Sportsbook/outcomes/with_outcome';

const outcomeButtonClassName = (selected, suspended) => classnames(
  'bvs-button-sport outcome-button bet-boost-button',
  {
    active: selected,
    disabled: suspended,
  },
);

const Outcome = ({
  overridenDesc,
  outcome,
  selected,
  suspended,
  toggleBetslip,
}) => (
  <div className="market-view-wrapper">
    <div className="outcome-description">{overridenDesc || outcome.desc}</div>

    <button
      type="button"
      className="bvs-button-sport outcome-button previous-price-button disabled"
    >
      <div className="outcome-n-wrapper bet" disabled="">
        <span className="outcome-n-price price">{outcome.formattedWasPrice}</span>
      </div>
    </button>

    <button type="button" className={outcomeButtonClassName(selected, suspended)}>
      <div className="outcome-n-wrapper bet" onClick={toggleBetslip}>
        <span className="outcome-n-price price">{outcome.formattedPrice}</span>
      </div>
    </button>
  </div>
);

Outcome.propTypes = {
  overridenDesc: PropTypes.string.isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  suspended: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  toggleBetslip: PropTypes.func.isRequired,
};

export default withOutcome(Outcome);
